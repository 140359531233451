import React, { useState, useEffect, useContext } from 'react';
import { useCart } from '../Context/CartContext';
import env_constants from '../Constants';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthContext } from '../Context/AuthContext';
import { useNavigate } from 'react-router-dom';
import axios from '../Axiosapi';

const CheckoutForm = ({ subtotal, signupDiscount, shippingCost, gstAmount, planDiscountAmount, shippsub, totalIncludingTax }) => {
    const { cart, clearCart } = useCart();
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;
    const { user, token } = useContext(AuthContext);
    const [paypalKeys, setPaypalKeys] = useState({});
    const navigate = useNavigate();

    const [addresses, setAddresses] = useState([]);
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [isShippingDifferent, setIsShippingDifferent] = useState(false);
    const [gst, setGst] = useState(0);
    const [deliveryCharges, setDeliveryCharges] = useState(0);

    useEffect(() => {
        if (user) {
            fetchAddresses();
        }
    }, [user]);

    useEffect(() => {
        fetchGstAndDeliveryCharges();
        fetchPaypalKeys();
    }, []);



    const fetchAddresses = async () => {
        try {
            const response = await axios.get(`${env_constants.API_BASE_URL}/user-addresses`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            setAddresses(response.data.data.shipping_addresses);
        } catch (error) {
            console.error('Error fetching addresses:', error);
        }
    };

    const fetchGstAndDeliveryCharges = async () => {
        try {
            const response = await axios.get(`${env_constants.API_BASE_URL}/brands`);
            const generalSettings = response.data.general_settings;
            setGst(parseFloat(generalSettings._gst_percentage) || 0);
            setDeliveryCharges(parseFloat(generalSettings._delivery_charges) || 0);
        } catch (error) {
            console.error('Error fetching GST and delivery charges:', error);
        }
    };

    const fetchPaypalKeys = async () => {
        try {
            const response = await axios.get(`${env_constants.API_BASE_URL}/encrypted-paypal-keys`);
            const { key } = response.data;

            // Decode the key
            const decodedKey = atob(key);

            setPaypalKeys({
                liveKey: decodedKey,
            });
        } catch (error) {
            console.error('Error fetching or decoding PayPal keys:', error);
        }
    };

    const predefinedAddress = {
        f_name: 'test',
        l_name: 'test',
        email: 'test@gmail.com',
        phone: '0307168896',
        street_address: 'test',
        city: 'test',
        state: 'test',
        post_code: '23002'
    };

    const [formData, setFormData] = useState({
        f_name: '',
        l_name: '',
        email: '',
        phone: '',
        street_address: '',
        city: '',
        state: '',
        post_code: '',
        company: '',
        suburb: 'Suburbia',
        status: 'pending',
        subTotal: 0,
        totalGst: 0,
        total_amount: 0,
        grand_total: 0,
        discountedAmount: 0,
        discountPlan: null,
        auth_discount_per: 0,
        auth_discount: 0,
        channel: 'Web',
        payment_status: 'pending',
        payment_method: '',
        delivery_charges: 0,
        admin_delete: false,
        card_number: '',
        exp_date: '',
        cvc: '',
        comment: '',
        payment_intent_id: '',
        user_id: user ? user.id : 0,
        s_f_name: '',
        s_l_name: '',
        s_email: '',
        s_phone: '',
        s_street_address: '',
        s_city: '',
        s_state: '',
        s_post_code: '',
        s_suburb: 'Suburbia',
        s_company: '',
    });


    const [activePaymentMethod, setActivePaymentMethod] = useState(null);
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitError, setSubmitError] = useState('');
    const [isPaypalSelected, setIsPaypalSelected] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const discountamount = signupDiscount + planDiscountAmount

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };



    {/* const calculateCartTotals = (cart) => {
        let discountProductPrice = 0;
        const subTotal = cart.reduce((acc, item) => {
            const priceMeta = item.product_meta.find(meta => meta.option === '_price');
            const discountMeta = item.discount_data;
            const price = priceMeta ? parseFloat(priceMeta.value) : 0;
            const discountPrice = discountMeta ? parseFloat(discountMeta.discounted_price) : price;
            discountProductPrice += (price - discountPrice) * item.quantity; // Aggregate the discount product price
            return acc + discountPrice * item.quantity;
        }, 0);

        const finalDeliveryCharges = subTotal > 100 ? 0 : shippingCost;
        const discountedAmount = 0; // Removed subscription discount
        const finalSubTotal = subTotal - discountedAmount;
        const shipsub = finalSubTotal + finalDeliveryCharges;
        const totalGst = shipsub * gst / 100;
        const finalGrandTotal = finalSubTotal + totalGst + finalDeliveryCharges;
        const finaldiscount = discountProductPrice + discountedAmount;
        const grandTotal = finalGrandTotal;

        return {
            subTotal: subTotal.toFixed(2),
            totalGst: totalGst.toFixed(2),
            grandTotal: grandTotal.toFixed(2),
            discountedAmount: finaldiscount.toFixed(2), // Updated discountedAmount
            delivery_charges: finalDeliveryCharges.toFixed(2)
        };
    };*/}

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        let formattedValue = value;

        const alphabeticFields = ['f_name', 'l_name', 'city', 'state', 's_f_name', 's_l_name', 's_city', 's_state'];
        const numericFields = ['phone', 'post_code', 's_phone', 's_post_code'];
        const alphanumericFields = ['street_address', 's_street_address'];

        if (alphabeticFields.includes(name)) {
            const regex = /^[A-Za-z\s]*$/;
            if (!regex.test(value)) {
                setErrors((prevErrors) => ({ ...prevErrors, [name]: 'Only alphabetic characters are allowed' }));
                return;
            } else {
                setErrors((prevErrors) => {
                    const { [name]: removedError, ...restErrors } = prevErrors;
                    return restErrors;
                });
            }
        }

        if (numericFields.includes(name)) {
            const regex = /^[0-9]*$/;
            if (!regex.test(value)) {
                setErrors((prevErrors) => ({ ...prevErrors, [name]: 'Only numeric characters are allowed' }));
                return;
            } else {
                setErrors((prevErrors) => {
                    const { [name]: removedError, ...restErrors } = prevErrors;
                    return restErrors;
                });
            }
        }

        if (alphanumericFields.includes(name)) {
            if (value.trim() === '') {
                setErrors((prevErrors) => ({ ...prevErrors, [name]: 'Street address cannot be empty' }));
                return;
            } else {
                setErrors((prevErrors) => {
                    const { [name]: removedError, ...restErrors } = prevErrors;
                    return restErrors;
                });
            }
        }

        if (name === 'email' || name === 's_email') {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(value)) {
                setErrors((prevErrors) => ({ ...prevErrors, [name]: 'Invalid email format' }));
            } else {
                setErrors((prevErrors) => {
                    const { [name]: removedError, ...restErrors } = prevErrors;
                    return restErrors;
                });
            }
        }

        if (name === 'exp_date') {
            formattedValue = value.replace(/\D/g, '');
            if (formattedValue.length > 6) formattedValue = formattedValue.slice(0, 6);
            if (formattedValue.length > 2) {
                formattedValue = `${formattedValue.slice(0, 2)}/${formattedValue.slice(2)}`;
            }
        } else if (name === 'card_number') {
            formattedValue = value.replace(/\D/g, '').slice(0, 16);
            formattedValue = formattedValue.replace(/(.{4})/g, '$1 ').trim();
        } else if (name === 'cvc') {
            formattedValue = value.slice(0, 3);
        }

        setFormData({ ...formData, [name]: formattedValue });
    };

    const handlePaymentMethodClick = (method) => {
        //if (method === 'Paypal' && !isFormValid()) {
        //    toast.error('Please fill in all required fields before selecting PayPal.');
        //    return;
        //}
        setSubmitError('');
        setActivePaymentMethod(method);
        if (method === 'Paypal') {
            setIsPaypalSelected(true);
        } else {
            setIsPaypalSelected(false);
        }
    };

    const isFormValid = () => {
        const requiredFields = ['f_name', 'l_name', 'email', 'phone', 'street_address', 'city', 'state', 'post_code'];
        for (let field of requiredFields) {
            if (!formData[field]) {
                return false;
            }
        }
        if (isShippingDifferent) {
            const shippingFields = ['s_f_name', 's_l_name', 's_email', 's_phone', 's_street_address', 's_city', 's_state', 's_post_code'];
            for (let field of shippingFields) {
                if (!formData[field]) {
                    return false;
                }
            }
        }
        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        setIsSubmitting(true);

        const newErrors = validateForm();
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            setIsSubmitting(false);
            return;
        }

        if (!cart || cart.length === 0) {
            toast.error('Cart is empty. Please add items to the cart before placing an order.');
            setIsSubmitting(false);
            return;
        }

        if (formData.subTotal === '0.00') {
            toast.error('Subtotal cannot be zero. Please add items to the cart.');
            setIsSubmitting(false);
            return;
        }

        if (activePaymentMethod !== 'Paypal') {
            await placeOrder();
        }
    };

    const placeOrder = async (transactionId = null) => {
        const cartData = cart.map((item) => {
            const priceMeta = item.product_meta.find((meta) => meta.option === '_price');
            const discountMeta = item.discount_data;
            const price = priceMeta ? parseFloat(priceMeta.value) : 0;
            const discountPrice = discountMeta ? parseFloat(discountMeta.discounted_price) : price;

            return {
                id: item.id,
                quantity: item.quantity,
                qp_subtotal: (discountPrice * item.quantity).toFixed(2),
            };
        });


        const dataToSend = {
            ...formData,
            products: cartData,
            payment_method: activePaymentMethod,
            total_amount: parseFloat(totalIncludingTax.toFixed(2)),
            payment_intent_id: transactionId || formData.payment_intent_id,
            payment_status: activePaymentMethod === 'Paypal' ? 'completed' : 'pending',
            exp_date: formData.exp_date.replace('/', ','),
            auth_discount_per: formData.auth_discount_per,
            auth_discount: formData.discountedAmount,
            totalGst: parseFloat(gstAmount.toFixed(2)),
            subTotal: parseFloat(subtotal.toFixed(2)),
            grand_total: parseFloat(totalIncludingTax.toFixed(2)),
            discountedAmount: parseFloat(discountamount.toFixed(2)),
            auth_discount_per: parseFloat(signupDiscount.toFixed(2)),
            delivery_charges: parseFloat(shippingCost.toFixed(2)),
        };

        try {
            const response = await fetch(`${env_constants.API_BASE_URL}/place-order`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(dataToSend),
            });

            if (response.ok) {
                const result = await response.json();
                console.log('Order submitted successfully:', result);

                navigate('/thank-you');
                clearCart();
                toast.success('Order placed successfully!');
            } else {
                console.error('Failed to submit order');
                const errorData = await response.json();
                toast.error(`Order submission failed: ${errorData.message}`);
            }
        } catch (error) {
            console.error('Error submitting order:', error);
            toast.error('Error submitting order. Please try again later.');
        } finally {
            setIsSubmitting(false);
        }
    };

    const validateForm = () => {
        let newErrors = {};

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const alphabeticFields = ['f_name', 'l_name', 'city', 'state', 's_f_name', 's_l_name', 's_city', 's_state'];
        const numericFields = ['phone', 'post_code', 's_phone', 's_post_code'];
        const alphanumericFields = ['street_address', 's_street_address'];

        if (!formData.f_name) newErrors.f_name = 'First name is required';
        if (!formData.l_name) newErrors.l_name = 'Last name is required';
        if (!formData.email) {
            newErrors.email = 'Email is required';
        } else if (!emailRegex.test(formData.email)) {
            newErrors.email = 'Invalid email format';
        }
        if (!formData.phone) newErrors.phone = 'Phone number is required';
        if (!formData.street_address || formData.street_address.trim() === '') {
            newErrors.street_address = 'Street address is required';
        }
        if (!formData.city) newErrors.city = 'City is required';
        if (!formData.state) newErrors.state = 'State is required';
        if (!formData.post_code) newErrors.post_code = 'Post code is required';
        if (!activePaymentMethod) newErrors.payment_method = 'Payment method is required';

        if (activePaymentMethod === 'Credit Card') {
            if (!formData.card_number) {
                newErrors.card_number = 'Card number is required';
            } else if (!isValidCreditCard(formData.card_number)) {
                newErrors.card_number = 'Invalid card number';
            }
            if (!formData.exp_date) {
                newErrors.exp_date = 'Expiration date is required';
            } else if (!isValidExpDate(formData.exp_date)) {
                newErrors.exp_date = 'Invalid expiration date';
            }
            if (!formData.cvc) {
                newErrors.cvc = 'CVC is required';
            } else if (!isValidCVC(formData.cvc)) {
                newErrors.cvc = 'Invalid CVC';
            }
        }

        if (isShippingDifferent) {
            if (!formData.s_f_name) newErrors.s_f_name = 'First name is required';
            if (!formData.s_l_name) newErrors.s_l_name = 'Last name is required';
            if (!formData.s_email) {
                newErrors.s_email = 'Email is required';
            } else if (!emailRegex.test(formData.s_email)) {
                newErrors.s_email = 'Invalid email format';
            }
            if (!formData.s_phone) newErrors.s_phone = 'Phone number is required';
            if (!formData.s_street_address || formData.s_street_address.trim() === '') {
                newErrors.s_street_address = 'Street address is required';
            }
            if (!formData.s_city) newErrors.s_city = 'City is required';
            if (!formData.s_state) newErrors.s_state = 'State is required';
            if (!formData.s_post_code) newErrors.s_post_code = 'Post code is required';
        }

        setErrors(newErrors);
        return newErrors;
    };

    const isValidCreditCard = (cardNumber) => {
        const regex = /^[0-9]{16}$/;
        return regex.test(cardNumber.replace(/\s+/g, ''));
    };

    const isValidExpDate = (expDate) => {
        const [month, year] = expDate.split('/');
        if (!month || year.length !== 4 || !year) return false;
        const monthNum = parseInt(month, 10);
        const yearNum = parseInt(year, 10);

        if (monthNum < 1 || monthNum > 12 || yearNum < currentYear) {
            return false;
        }

        if (yearNum === currentYear && monthNum < currentMonth) {
            return false;
        }

        return true;
    };

    const isValidCVC = (cvc) => {
        const regex = /^[0-9]{3}$/;
        return regex.test(cvc);
    };

    useEffect(() => {
        if (activePaymentMethod === 'Paypal') {
            //setActivePaymentMethod(method);
            if (!isFormValid()) {
                toast.error('Please fill in all required fields before selecting PayPal.');
                setIsPaypalSelected(true);
                return;
            }
            else {
                const cartData = cart.map((item) => {
                    const priceMeta = item.product_meta.find((meta) => meta.option === '_price');
                    const discountMeta = item.discount_data;
                    const price = priceMeta ? parseFloat(priceMeta.value) : 0;
                    const discountPrice = discountMeta ? parseFloat(discountMeta.discounted_price) : price;

                    return {
                        id: item.id,
                        quantity: item.quantity,
                        qp_subtotal: (discountPrice * item.quantity).toFixed(2),
                    };
                });


                const dataToSend = {
                    ...formData,
                    products: cartData,
                    payment_method: activePaymentMethod,
                    total_amount: parseFloat(totalIncludingTax.toFixed(2)),
                    payment_status: activePaymentMethod === 'Paypal' ? 'completed' : 'pending',
                    exp_date: formData.exp_date.replace('/', ','),
                    auth_discount_per: formData.auth_discount_per,
                    auth_discount: formData.discountedAmount,
                    totalGst: parseFloat(gstAmount.toFixed(2)),
                    subTotal: parseFloat(subtotal.toFixed(2)),
                    grand_total: parseFloat(totalIncludingTax.toFixed(2)),
                    discountedAmount: parseFloat(discountamount.toFixed(2)),
                    auth_discount_per: parseFloat(signupDiscount.toFixed(2)),
                    delivery_charges: parseFloat(shippingCost.toFixed(2)),
                };

                // Store dataToSend in session storage
                sessionStorage.setItem('paypalCheckoutData', JSON.stringify(dataToSend));

                setTimeout(() => {
                    sessionStorage.removeItem('paypalCheckoutData');
                }, 300000);
                setIsPaypalSelected(false);
                navigate('/checkout-paypal-payment');
            }

        }
    }, [activePaymentMethod, paypalKeys]);



    const handleAddressSelection = (address) => {
        setSelectedAddress(address);
        setFormData(prevState => ({
            ...prevState,
            f_name: address.f_name,
            l_name: address.l_name,
            email: address.email,
            phone: address.phone,
            street_address: address.street_address,
            city: address.city,
            state: address.state,
            post_code: address.post_code
        }));
        setIsSidebarOpen(false);
    };

    const handleShippingCheckboxChange = (e) => {
        setIsShippingDifferent(e.target.checked);
    };

    return (
        <div>
            <div className={`checkout-addresses-sidebar ${isSidebarOpen ? 'open' : ''}`}>
                {addresses.map((address, index) => (
                    <div key={index} className='address-item'>
                        <div className='border border-danger rounded p-4'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <h4 className='m-0'>{`Address ${index + 1}`}</h4>
                                <input
                                    type='radio'
                                    name='selectedAddress'
                                    onChange={() => handleAddressSelection(address)}
                                />
                            </div>
                            <table className='mt-3'>
                                <tbody>
                                    <tr>
                                        <td className='pb-2'>F-Name</td>
                                        <td className='pb-2'>:</td>
                                        <td className='pb-2'>{address.f_name}</td>
                                    </tr>
                                    <tr>
                                        <td className='pb-2'>L-Name</td>
                                        <td className='pb-2'>:</td>
                                        <td className='pb-2'>{address.l_name}</td>
                                    </tr>
                                    <tr>
                                        <td className='pb-2'>Email</td>
                                        <td className='pb-2'>:</td>
                                        <td className='pb-2' style={{ wordBreak: 'break-all' }}>{address.email}</td>
                                    </tr>
                                    <tr>
                                        <td className='pb-2'>Phone</td>
                                        <td className='pb-2'>:</td>
                                        <td className='pb-2'>{address.phone}</td>
                                    </tr>
                                    <tr>
                                        <td className='pb-2'>Address</td>
                                        <td className='pb-2'>:</td>
                                        <td className='pb-2'>{address.street_address}</td>
                                    </tr>
                                    <tr>
                                        <td className='pb-2'>City</td>
                                        <td className='pb-2'>:</td>
                                        <td className='pb-2'>{address.city}</td>
                                    </tr>
                                    <tr>
                                        <td className='pb-2'>State</td>
                                        <td className='pb-2'>:</td>
                                        <td className='pb-2'>{address.state}</td>
                                    </tr>
                                    <tr>
                                        <td className='pb-2 pe-2'>Post Code</td>
                                        <td className='pb-2'>:</td>
                                        <td className='pb-2'>{address.post_code}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                ))}
            </div>

            <form onSubmit={handleSubmit}>
                <div className='checkout-personal-information'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <h2 className='personal-heading'>Billing Details</h2>
                        {addresses.length > 0 && (
                            <button type='button' className="sidebar-toggle-btn" onClick={toggleSidebar}>
                                Show Addresses
                            </button>
                        )}
                    </div>

                    <div className='row mt-4'>
                        <div className='col-md-6 mb-3'>
                            <input
                                type='text'
                                name='f_name'
                                placeholder='First Name'
                                className='personal-information-fields'
                                value={formData.f_name}
                                onChange={handleInputChange}
                            />
                            {errors.f_name && <p className='error-text'>{errors.f_name}</p>}
                        </div>

                        <div className='col-md-6 mb-3'>
                            <input
                                type='text'
                                name='l_name'
                                placeholder='Last Name'
                                className='personal-information-fields'
                                value={formData.l_name}
                                onChange={handleInputChange}
                            />
                            {errors.l_name && <p className='error-text'>{errors.l_name}</p>}
                        </div>

                        <div className='col-md-6 mb-3'>
                            <input
                                type='email'
                                name='email'
                                placeholder='Email'
                                className='personal-information-fields'
                                value={formData.email}
                                onChange={handleInputChange}
                            />
                            {errors.email && <p className='error-text'>{errors.email}</p>}
                        </div>

                        <div className='col-md-6 mb-3'>
                            <input
                                type='tel'
                                name='phone'
                                placeholder='Phone'
                                className='personal-information-fields'
                                value={formData.phone}
                                onChange={handleInputChange}
                            />
                            {errors.phone && <p className='error-text'>{errors.phone}</p>}
                        </div>

                        <div className='col-md-12 mb-3'>
                            <input
                                type='text'
                                name='street_address'
                                placeholder='Street Address'
                                className='personal-information-fields'
                                value={formData.street_address}
                                onChange={handleInputChange}
                            />
                            {errors.street_address && <p className='error-text'>{errors.street_address}</p>}
                        </div>

                        <div className='col-md-6 mb-3'>
                            <input
                                type='text'
                                name='city'
                                placeholder='City'
                                className='personal-information-fields'
                                value={formData.city}
                                onChange={handleInputChange}
                            />
                            {errors.city && <p className='error-text'>{errors.city}</p>}
                        </div>

                        <div className='col-md-6 mb-3'>
                            <input
                                type='text'
                                name='state'
                                placeholder='State'
                                className='personal-information-fields'
                                value={formData.state}
                                onChange={handleInputChange}
                            />
                            {errors.state && <p className='error-text'>{errors.state}</p>}
                        </div>

                        <div className='col-md-6 mb-3'>
                            <input
                                type='number'
                                name='post_code'
                                placeholder='Post Code'
                                className='personal-information-fields'
                                value={formData.post_code}
                                onChange={handleInputChange}
                            />
                            {errors.post_code && <p className='error-text'>{errors.post_code}</p>}
                        </div>

                        <div className='col-md-6 mb-3'>
                            <input
                                type='text'
                                name='company'
                                placeholder='Company (Optional)'
                                className='personal-information-fields'
                                value={formData.company}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className='col-md-12 mb-3'>
                            <label className='d-flex align-items-center'>
                                <input
                                    type='checkbox'
                                    className='me-2'
                                    onChange={handleShippingCheckboxChange}
                                />
                                Ship to a different address
                            </label>
                        </div>
                        {isShippingDifferent && (
                            <div className='col-md-12'>
                                <h2 className='personal-heading mb-4'>Shipping Details</h2>
                                <div className='row'>
                                    <div className='col-md-6 mb-3'>
                                        <input
                                            type='text'
                                            name='s_f_name'
                                            placeholder='First Name'
                                            className='personal-information-fields'
                                            value={formData.s_f_name}
                                            onChange={handleInputChange}
                                        />
                                        {errors.s_f_name && <p className='error-text'>{errors.s_f_name}</p>}
                                    </div>

                                    <div className='col-md-6 mb-3'>
                                        <input
                                            type='text'
                                            name='s_l_name'
                                            placeholder='Last Name'
                                            className='personal-information-fields'
                                            value={formData.s_l_name}
                                            onChange={handleInputChange}
                                        />
                                        {errors.s_l_name && <p className='error-text'>{errors.s_l_name}</p>}
                                    </div>

                                    <div className='col-md-6 mb-3'>
                                        <input
                                            type='email'
                                            name='s_email'
                                            placeholder='Email'
                                            className='personal-information-fields'
                                            value={formData.s_email}
                                            onChange={handleInputChange}
                                        />
                                        {errors.s_email && <p className='error-text'>{errors.s_email}</p>}
                                    </div>

                                    <div className='col-md-6 mb-3'>
                                        <input
                                            type='tel'
                                            name='s_phone'
                                            placeholder='Phone'
                                            className='personal-information-fields'
                                            value={formData.s_phone}
                                            onChange={handleInputChange}
                                        />
                                        {errors.s_phone && <p className='error-text'>{errors.s_phone}</p>}
                                    </div>

                                    <div className='col-md-12 mb-3'>
                                        <input
                                            type='text'
                                            name='s_street_address'
                                            placeholder='Street Address'
                                            className='personal-information-fields'
                                            value={formData.s_street_address}
                                            onChange={handleInputChange}
                                        />
                                        {errors.s_street_address && <p className='error-text'>{errors.s_street_address}</p>}
                                    </div>

                                    <div className='col-md-6 mb-3'>
                                        <input
                                            type='text'
                                            name='s_city'
                                            placeholder='City'
                                            className='personal-information-fields'
                                            value={formData.s_city}
                                            onChange={handleInputChange}
                                        />
                                        {errors.s_city && <p className='error-text'>{errors.s_city}</p>}
                                    </div>

                                    <div className='col-md-6 mb-3'>
                                        <input
                                            type='text'
                                            name='s_state'
                                            placeholder='State'
                                            className='personal-information-fields'
                                            value={formData.s_state}
                                            onChange={handleInputChange}
                                        />
                                        {errors.s_state && <p className='error-text'>{errors.s_state}</p>}
                                    </div>

                                    <div className='col-md-6 mb-3'>
                                        <input
                                            type='number'
                                            name='s_post_code'
                                            placeholder='Post Code'
                                            className='personal-information-fields'
                                            value={formData.s_post_code}
                                            onChange={handleInputChange}
                                        />
                                        {errors.s_post_code && <p className='error-text'>{errors.s_post_code}</p>}
                                    </div>

                                    <div className='col-md-6 mb-3'>
                                        <input
                                            type='text'
                                            name='s_company'
                                            placeholder='Company (Optional)'
                                            className='personal-information-fields'
                                            value={formData.s_company}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <div className='checkout-payment-box'>
                    <h2 className='personal-heading'>Payment Method</h2>
                    <div className='d-flex flex-wrap gap-3 mt-4'>
                        {['Credit Card', 'Paypal'].map((method, index) => (
                            <button
                                type='button'
                                className={`payment-buttons ${activePaymentMethod === method ? 'active' : ''}`}
                                onClick={() => handlePaymentMethodClick(method)}
                                key={index}
                            >
                                {method}
                            </button>
                        ))}
                    </div>
                    {errors.payment_method && <p className='error-text'>{errors.payment_method}</p>}

                    {activePaymentMethod === 'Credit Card' && (
                        <div className='mt-4'>
                            <div className='row'>
                                <div className='col-md-12 mb-3'>
                                    <input
                                        type='text'
                                        className='personal-information-fields'
                                        placeholder='Credit Card Number'
                                        name='card_number'
                                        value={formData.card_number}
                                        onChange={handleInputChange}
                                    />
                                    {errors.card_number && <p className='error-text'>{errors.card_number}</p>}
                                </div>

                                <div className='col-md-6 mb-3'>
                                    <input
                                        type='text'
                                        className='personal-information-fields'
                                        placeholder='Expiration Date (MM/YYYY)'
                                        name='exp_date'
                                        value={formData.exp_date}
                                        onChange={handleInputChange}
                                    />
                                    {errors.exp_date && <p className='error-text'>{errors.exp_date}</p>}
                                </div>

                                <div className='col-md-6 mb-3'>
                                    <input
                                        type='text'
                                        className='personal-information-fields'
                                        placeholder='CVC'
                                        name='cvc'
                                        value={formData.cvc}
                                        onChange={handleInputChange}
                                    />
                                    {errors.cvc && <p className='error-text'>{errors.cvc}</p>}
                                </div>
                            </div>
                        </div>
                    )}

                    {activePaymentMethod === 'Paypal' && (
                        <div className='mt-4'>

                        </div>
                    )}
                </div>

                <div className='checkout-comment'>
                    <h2 className='personal-heading'>Comment (Optional)</h2>
                    <textarea
                        name='comment'
                        placeholder='Write Comment'
                        value={formData.comment}
                        onChange={handleInputChange}
                    ></textarea>
                </div>

                {!isPaypalSelected && (
                    <button type='submit' className='order-place-btn'>
                        {isSubmitting ? 'Placing Order...' : 'Place Order'}
                    </button>
                )}

                {submitError && <p className='error-text'>{submitError}</p>}
            </form>
        </div>
    );
};

export default CheckoutForm;
