import React, { useState, useEffect } from 'react';
import axios from '../Axiosapi';
import env_constants from '../Constants';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useCart } from '../Context/CartContext';

const CheckoutPapalPayment = () => {
    const { cart, clearCart } = useCart();
    const [paypalKeys, setPaypalKeys] = useState({});
    const [activePaymentMethod, setActivePaymentMethod] = useState('Paypal');
    const [formData, setFormData] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();
    const cartItems = JSON.parse(localStorage.getItem('cartItems')) || [];

    useEffect(() => {
        if (!cart || cart.length === 0) {
            navigate('/'); // Redirect to home if cart is empty
        }
    }, [cart, navigate]);

    const fetchPaypalKeys = async () => {
        try {
            const response = await axios.get(`${env_constants.API_BASE_URL}/encrypted-paypal-keys`);
            const { key } = response.data;

            // Decode the key
            const decodedKey = atob(key);
            setPaypalKeys({
                liveKey: decodedKey,
            });
        } catch (error) {
            console.error('Error fetching or decoding PayPal keys:', error);
        }
    };

    useEffect(() => {
        fetchPaypalKeys();
    }, []);

    const storedData = sessionStorage.getItem('paypalCheckoutData');
    const data = storedData ? JSON.parse(storedData) : {};

    const safeToFixed = (value, decimals = 2) => {
        return value !== undefined ? value.toFixed(decimals) : '0.00';
    };

    const placeOrder = async (paymentIntentId) => {
        const dataToSend = {
            ...data,
            payment_intent_id: paymentIntentId,
        };

        try {
            setIsSubmitting(true);
            const response = await fetch(`${env_constants.API_BASE_URL}/place-order`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(dataToSend),
            });

            if (response.ok) {
                const result = await response.json();
                toast.success('Order placed successfully!');
                navigate('/thank-you');
                clearCart();
            } else {
                const errorData = await response.json();
                toast.error(`Order submission failed: ${errorData.message}`);
            }
        } catch (error) {
            toast.error('Error submitting order. Please try again later.');
        } finally {
            setIsSubmitting(false);
        }
    };

    const totalAmount = safeToFixed(data.grand_total);

    useEffect(() => {
        if (activePaymentMethod === 'Paypal' && paypalKeys.liveKey) {
            setTimeout(() => {
                sessionStorage.removeItem('paypalCheckoutData');
            }, 5000);
            const paypalScript = document.createElement('script');
            paypalScript.src = `https://www.paypal.com/sdk/js?client-id=${paypalKeys.liveKey}&currency=USD&disable-funding=card,paylater`;
            paypalScript.async = true;

            paypalScript.onload = () => {
                if (window.paypal && !document.getElementById('paypal-button')) {
                    window.paypal.Buttons({
                        createOrder: (data, actions) => {
                            if (parseFloat(totalAmount) <= 0) {
                                toast.error('Total amount must be greater than zero.');
                                return;
                            }

                            return actions.order.create({
                                purchase_units: [{
                                    amount: {
                                        value: totalAmount,
                                    },
                                }],
                            });
                        },
                        onApprove: async (data, actions) => {
                            try {
                                const order = await actions.order.capture();
                                setFormData({ ...formData, payment_intent_id: order.id });
                                await placeOrder(order.id);
                            } catch (error) {
                                console.error('Error capturing order:', error);
                                toast.error('Error capturing PayPal order. Please try again later.');
                            }
                        },
                        onError: (err) => {
                            console.error('PayPal Button Error:', err);
                            if (err?.details?.[0]?.issue === 'PAYEE_ACCOUNT_RESTRICTED') {
                                toast.error('The PayPal account is restricted. Please contact support.');
                            } else {
                                toast.error('An error occurred with PayPal. Please try again later.');
                            }
                        },
                    }).render('#paypal-button-container');
                }
            };

            document.body.appendChild(paypalScript);

            return () => {
                document.body.removeChild(paypalScript);
            };
        }
    }, [paypalKeys.liveKey, activePaymentMethod]);

    // Redirect after 5 minutes
    useEffect(() => {
        const timer = setTimeout(() => {
            navigate('/checkout');
        }, 300000); // 5 minutes

        return () => clearTimeout(timer); // Cleanup on component unmount
    }, [navigate]);

    return (
        <div className="container">
            <div className="cpp-box">
                <img src="/yazi-logo.png" width="15%" alt="yazi logo" className='cpp-logo' />
                <div className="cpp-title">
                    <h2>Complete Your Payments</h2>
                    <p>${safeToFixed(data.grand_total)}</p>
                </div>
                <div className="cpp-payment my-2">
                    <div id="paypal-button-container"></div>
                </div>
                <div className="cpp-billing-address">
                    <h3>Billing Address</h3>
                    <p>Name: <span>{`${data.f_name || ''} ${data.l_name || ''}`}</span></p>
                    <p>Email: <span>{data.email || ''}</span></p>
                    <p>Phone: <span>{data.phone || ''}</span></p>
                    <p>Address: <span>{data.street_address || ''}</span></p>
                    <p>Area Detail: <span>{data.post_code || ''}, {data.state || ''}, {data.city || ''}</span></p>
                </div>
                <div className="cpp-items mt-4">
                    <h3>Product Items</h3>
                    <div className='table-wrapper'>
                        <table width="100%">
                            <thead>
                                <tr>
                                    <th>Item</th>
                                    <th>Price</th>
                                    <th>Quantity</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {cartItems.map((product) => {
                                    const priceMeta = product.product_meta.find(meta => meta.option === '_price');
                                    const price = priceMeta ? parseFloat(priceMeta.value) : 0; // Get price or default to 0
                                    const totalPrice = price * (product.quantity || 0); // Calculate total for the item

                                    return (
                                        <tr key={product.id}>
                                            <td>
                                                <div className="cpp-item-product">
                                                    <img src={`https://admin.yazi.com.au/${product.featured_image}`} alt={product.title} />
                                                    <h5>{product.title}</h5>
                                                </div>
                                            </td>
                                            <td>${safeToFixed(price)}</td> {/* Display product price */}
                                            <td>{product.quantity || 0}</td>
                                            <td>${safeToFixed(totalPrice)}</td> {/* Display total price */}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="cpp-total mt-4">
                    <h3>Order Summary</h3>
                    <p>Subtotal: <span>${safeToFixed(data.subTotal)}</span></p>
                    <p>Shipping Cost: <span>${safeToFixed(data.delivery_charges)}</span></p>
                    <p>GST: <span>${safeToFixed(data.totalGst)}</span></p>
                    <div><p>Total: <span>${safeToFixed(data.grand_total)}</span></p></div>
                </div>
            </div>
        </div>
    );
};

export default CheckoutPapalPayment;
