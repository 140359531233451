import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Home from './Pages/Home';
import Shop from './Pages/Shop';
import SingleProduct from './Pages/SingleProduct';
import Cart from './Pages/Cart';
import Checkout from './Pages/Checkout';
import Notfound from './Pages/404';
import ThankYou from './Pages/ThankYou';
import SignUp from './Pages/SignUp';
import SignIn from './Pages/SignIn';
import ForgetPassword from './Pages/ForgetPassword';
import ResetPassword from './Pages/ResetPassword';
import AboutUs from './Pages/AboutUs';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthProvider } from './Context/AuthContext';
import { CartProvider } from './Context/CartContext';
import Header from './Components/Header';
import Footer from './Components/Footer';
import MyAccount from './Pages/MyAccount';
import UpdataProfile from './Pages/UpdateProfile';
import OTP from './Pages/Otp';
import OrderHistory from './Pages/OrderHistory';
import Contactus from './Pages/Contactus';
import BestSelling from './Pages/BestSelling';
import TrackOrder from './Pages/TrackOrder';
import Address from './Pages/Address';
import UpdateAddress from './Pages/UpdateAddress';
import ReturnOrder from './Pages/ReturnOrder';
import SearchResult from './Pages/SearchResult';
import ScrollToTop from './Context/scrolltop';
import Toomanyrequests from './Pages/TwoManyRequest';
import Feedback from './Pages/feedback';
import ShippingOption from "./Pages/shipping-option";
import ReturnPolicy from "./Pages/ReturnPolicy";
import TermsConditions from './Pages/TermsConditions';
import EditorialGuidelines from './Pages/EditorialGuidelines';
import CheckoutPaypalPayment from './Pages/checkout-paypal-payment';

// Layout component to handle header and footer visibility
const Layout = ({ children }) => {
  const location = useLocation();
  const noHeaderFooterRoutes = ['/too-many-requests'];

  return (
    <>
      {!noHeaderFooterRoutes.includes(location.pathname) && <Header />}
      {children}
      {!noHeaderFooterRoutes.includes(location.pathname) && <Footer />}
    </>
  );
};

const App = () => {
  return (
    <CartProvider>
      <AuthProvider>
        <HelmetProvider>
          <Router>
            <ScrollToTop />
            <Layout>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/shop" element={<Shop />} />
                <Route path="/product/:sku" element={<SingleProduct />} />
                <Route path="/search-results" element={<SearchResult />} />
                <Route path="/cart" element={<Cart />} />
                <Route path="/checkout" element={<Checkout />} />
                <Route path="/thank-you" element={<ThankYou />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/signin" element={<SignIn />} />
                <Route path="/forget-password" element={<ForgetPassword />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/404" element={<Notfound />} />
                <Route path="/too-many-requests" element={<Toomanyrequests />} />
                <Route path="/about-us" element={<AboutUs />} />
                <Route path="/privacy-and-policy" element={<PrivacyPolicy />} />
                <Route path="/my-account" element={<MyAccount />} />
                <Route path="/profile-update" element={<UpdataProfile />} />
                <Route path="/otp" element={<OTP />} />
                <Route path="/order-history" element={<OrderHistory />} />
                <Route path="/contact-us" element={<Contactus />} />
                <Route path="/return-order" element={<ReturnOrder />} />
                <Route path="/best-selling" element={<BestSelling />} />
                <Route path="/track-order" element={<TrackOrder />} />
                <Route path="/address" element={<Address />} />
                <Route path="/update-address/:id" element={<UpdateAddress />} />
                <Route path="*" element={<Navigate to="/404" />} />
                <Route path="*" element={<Navigate to="/too-many-requests" />} />
                <Route path="/feedback" element={<Feedback />} />
                <Route path="/shipping-option" element={<ShippingOption />} />
                <Route path="/return-policy" element={<ReturnPolicy />} />
                <Route path='/terms-conditions' element={<TermsConditions />} />
                <Route path='/editorial-guidelines' element={<EditorialGuidelines />} />
                <Route path='/checkout-paypal-payment' element={<CheckoutPaypalPayment />} />
              </Routes>
            </Layout>
            <ToastContainer />
          </Router>
        </HelmetProvider>
      </AuthProvider>
    </CartProvider>
  );
};

export default App;
